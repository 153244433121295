define('a2b/components/itineraries-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['itineraries-list'],

    selectedItinerary: null,
    openedItinerary: null,
    actions: {
      itemHovered: function itemHovered(itinerary) {
        this.set('selectedItinerary', itinerary);
      },
      itemClicked: function itemClicked(itinerary) {
        this.set('selectedItinerary', itinerary);
        this.set('openedItinerary', itinerary);
      }
    }
  });
});