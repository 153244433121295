define('a2b/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    sessionAuthenticated: function sessionAuthenticated() {
      // do not redirect or anything
    },
    actions: {
      error: function error(_error) {
        if (_error) {
          this.controllerFor('application').set('error', _error);
        }
      },
      willTransition: function willTransition() {
        this.controllerFor('application').set('menuOpen', false);
      }
    }
  });
});