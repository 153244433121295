define('a2b/components/display-itineraries/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['row'],
    classNameBindings: ['media.isMobile:bottom-single:bottom-split'],
    itineraries: _ember['default'].A([]),
    selectedItinerary: null,
    openedItinerary: null,
    routeHovered: null,
    routeOpened: null,
    showDirectOnly: true,
    visibleItineraries: _ember['default'].computed('itineraries', 'showDirectOnly', function () {
      return this.get('showDirectOnly') ? this.get('itineraries').filter(isItineraryDirect) : this.get('itineraries');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('openedItinerary', null);
      this.set('routeHovered', null);
      this.set('routeOpened', null);
      this.set('selectedItinerary', this.get('itineraries').get('firstObject'));
      this.set('showDirectOnly', isAnyDirectItineraries(this.get('itineraries')));
    },

    didInsertElement: function didInsertElement() {
      if (this.get('media.isMobile')) {
        this.prepareForMobile();
      }
    },

    onOpenedItineraryChange: _ember['default'].observer('openedItinerary', function () {
      var itinerary = this.get('openedItinerary');
      if (itinerary !== null) {
        this.showItineraryDetails();
        // If there is only one route, show it right away
        if (isItineraryDirect(itinerary)) {
          this.set('routeHovered', getFirstRoute(itinerary));
          this.set('routeOpened', getFirstRoute(itinerary));
        }
        if (this.get('media.isMobile')) {
          this.hideMobileOverlay();
        }
      } else {
        if (this.get('media.isMobile')) {
          this.showMobileOverlay();
        }
        this.hideItineraryDetails();
      }
    }),
    onRouteOpenedChange: _ember['default'].observer('routeOpened', function () {
      this.get('routeOpened') !== null ? this.showRouteDetails() : this.hideRouteDetails();
    }),

    actions: {
      closeItinerary: function closeItinerary() {
        this.set('openedItinerary', null);
        this.set('routeHovered', null);
      },
      closeRoute: function closeRoute() {
        this.set('routeHovered', null);
        this.set('routeOpened', null);
        // Also close itinerary if it consists of only one route
        if (isItineraryDirect(this.get('openedItinerary'))) {
          this.set('openedItinerary', null);
        }
      },
      toggleDirectOnly: function toggleDirectOnly() {
        this.toggleProperty('showDirectOnly');
      }
    },

    showItineraryDetails: function showItineraryDetails() {
      this.$('#itinerary-details').removeClass('hidden');
    },
    hideItineraryDetails: function hideItineraryDetails() {
      this.$('#itinerary-details').addClass('hidden');
    },
    showRouteDetails: function showRouteDetails() {
      this.$('#route-details').removeClass('hidden');
    },
    hideRouteDetails: function hideRouteDetails() {
      this.$('#route-details').addClass('hidden');
    },
    prepareForMobile: function prepareForMobile() {
      this.$('body').removeClass('noscroll');
    },
    hideMobileOverlay: function hideMobileOverlay() {
      this.$('.body-shadow').removeClass('hidden');
      this.$('.bottom-single-overlay').removeClass('hidden');
    },
    showMobileOverlay: function showMobileOverlay() {
      this.$('.body-shadow').addClass('hidden');
      this.$('.bottom-single-overlay').addClass('hidden');
    }
  });

  var isItineraryDirect = function isItineraryDirect(itinerary) {
    return itinerary.get('stops') === 0;
  };

  var getFirstRoute = function getFirstRoute(itinerary) {
    return itinerary.get('routes').get('firstObject');
  };

  var isAnyDirectItineraries = function isAnyDirectItineraries(itineraries) {
    return itineraries.filter(isItineraryDirect).length !== 0;
  };
});