define("a2b/components/create-new-route-choose-location-city/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "a2b/components/create-new-route-choose-location-city/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "for", "city");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["content", "cityLabel", ["loc", [null, [2, 20], [2, 33]]], 0, 0, 0, 0], ["inline", "place-autocomplete-field", [], ["value", ["subexpr", "@mut", [["get", "cityText", ["loc", [null, [4, 8], [4, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "handlerController", ["subexpr", "@mut", [["get", "this", ["loc", [null, [5, 20], [5, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "inputClass", "form-control", "placeChangedCallback", "cityChanged", "restrictions", ["subexpr", "@mut", [["get", "countryRestriction", ["loc", [null, [8, 15], [8, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "Bangkok, Thailand"], ["loc", [null, [3, 2], [10, 4]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});