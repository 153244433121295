define('a2b/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    error: null,
    menuOpen: false,
    onErrorChanged: _ember['default'].observer('error', function () {
      if (this.get('error')) {
        $('.modal').modal();
        $('.modal').modal('show');
      }
    }),
    actions: {
      closeError: function closeError() {
        $('.modal').modal('hide');
      }
    }
  });
});