define('ember-cli-g-maps/components/g-maps', ['exports', 'ember-cli-g-maps/mixins/g-maps/core/main', 'ember-cli-g-maps/mixins/g-maps/core/two-way-lat-lng', 'ember-cli-g-maps/mixins/g-maps/core/two-way-zoom', 'ember-cli-g-maps/mixins/g-maps/core/two-way-draggable', 'ember-cli-g-maps/mixins/g-maps/core/two-way-disable-double-click-zoom', 'ember-cli-g-maps/mixins/g-maps/core/two-way-scroll-wheel', 'ember-cli-g-maps/mixins/g-maps/core/two-way-zoom-control', 'ember-cli-g-maps/mixins/g-maps/core/two-way-scale-control', 'ember-cli-g-maps/mixins/g-maps/core/two-way-map-type', 'ember-cli-g-maps/mixins/g-maps/core/two-way-map-type-control', 'ember-cli-g-maps/mixins/g-maps/core/two-way-styles', 'ember-cli-g-maps/mixins/g-maps/circles', 'ember-cli-g-maps/mixins/g-maps/markers', 'ember-cli-g-maps/mixins/g-maps/polygons', 'ember-cli-g-maps/mixins/g-maps/overlays', 'ember-cli-g-maps/mixins/g-maps/polylines', 'ember-cli-g-maps/mixins/g-maps/rectangles', 'ember-cli-g-maps/mixins/g-maps/heatmap', 'ember-cli-g-maps/mixins/g-maps/selections'], function (exports, _emberCliGMapsMixinsGMapsCoreMain, _emberCliGMapsMixinsGMapsCoreTwoWayLatLng, _emberCliGMapsMixinsGMapsCoreTwoWayZoom, _emberCliGMapsMixinsGMapsCoreTwoWayDraggable, _emberCliGMapsMixinsGMapsCoreTwoWayDisableDoubleClickZoom, _emberCliGMapsMixinsGMapsCoreTwoWayScrollWheel, _emberCliGMapsMixinsGMapsCoreTwoWayZoomControl, _emberCliGMapsMixinsGMapsCoreTwoWayScaleControl, _emberCliGMapsMixinsGMapsCoreTwoWayMapType, _emberCliGMapsMixinsGMapsCoreTwoWayMapTypeControl, _emberCliGMapsMixinsGMapsCoreTwoWayStyles, _emberCliGMapsMixinsGMapsCircles, _emberCliGMapsMixinsGMapsMarkers, _emberCliGMapsMixinsGMapsPolygons, _emberCliGMapsMixinsGMapsOverlays, _emberCliGMapsMixinsGMapsPolylines, _emberCliGMapsMixinsGMapsRectangles, _emberCliGMapsMixinsGMapsHeatmap, _emberCliGMapsMixinsGMapsSelections) {

  // Core //
  'use strict';

  exports['default'] = Ember.Component.extend(_emberCliGMapsMixinsGMapsCircles['default'], _emberCliGMapsMixinsGMapsMarkers['default'], _emberCliGMapsMixinsGMapsPolygons['default'], _emberCliGMapsMixinsGMapsOverlays['default'], _emberCliGMapsMixinsGMapsPolylines['default'], _emberCliGMapsMixinsGMapsRectangles['default'], _emberCliGMapsMixinsGMapsSelections['default'], _emberCliGMapsMixinsGMapsHeatmap['default'], _emberCliGMapsMixinsGMapsCoreTwoWayLatLng['default'], _emberCliGMapsMixinsGMapsCoreTwoWayZoom['default'], _emberCliGMapsMixinsGMapsCoreTwoWayDraggable['default'], _emberCliGMapsMixinsGMapsCoreTwoWayDisableDoubleClickZoom['default'], _emberCliGMapsMixinsGMapsCoreTwoWayScrollWheel['default'], _emberCliGMapsMixinsGMapsCoreTwoWayZoomControl['default'], _emberCliGMapsMixinsGMapsCoreTwoWayScaleControl['default'], _emberCliGMapsMixinsGMapsCoreTwoWayMapType['default'], _emberCliGMapsMixinsGMapsCoreTwoWayMapTypeControl['default'], _emberCliGMapsMixinsGMapsCoreTwoWayStyles['default'], _emberCliGMapsMixinsGMapsCoreMain['default']);
});

// Map Childs //

// Extensions //