define('a2b/components/itineraries-map/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    routeHovered: null,
    selectedItinerary: null,
    itineraries: _ember['default'].A([]),
    bounds: _ember['default'].computed('itineraries.[]', function () {
      return calcBoundsFromItineraries(this.get('itineraries'));
    }),
    markers: _ember['default'].computed('itineraries.[]', 'selectedItinerary', 'routeHovered', function () {
      var _this = this;

      return _ember['default'].A(this.get('itineraries').reduce(function (markers, iti) {
        return markers.concat(iti.get('routes').reduce(function (mrks, route) {
          return mrks.concat([_this.createMarker(iti, route, "from"), _this.createMarker(iti, route, "to")]);
        }, []));
      }, []));
    }),
    lines: _ember['default'].computed('itineraries.[]', 'selectedItinerary', 'routeHovered', function () {
      var _this2 = this;

      return _ember['default'].A(this.get('itineraries').reduce(function (lines, iti) {
        return lines.concat(iti.get('routes').map(function (route) {
          return {
            id: route.get('id'),
            from: [route.get('fromLat'), route.get('fromLng')],
            to: [route.get('toLat'), route.get('toLng')],
            style: _this2.getLineStyle(iti, route)
          };
        }));
      }, []));
    }),
    getLineStyle: function getLineStyle(itinerary, route) {
      return this.isRouteSelected(route) ? 'selected' : this.isItinerarySelected(itinerary) ? 'normal' : 'dimmed';
    },
    isItinerarySelected: function isItinerarySelected(itinerary) {
      return this.get('selectedItinerary.id') === itinerary.get('id');
    },
    isRouteSelected: function isRouteSelected(route) {
      return this.get('routeHovered.id') === route.get('id');
    },
    createMarker: function createMarker(itinerary, route, prefix) {
      return {
        id: route.get('id') + '-' + prefix,
        coords: [route.get(prefix + 'Lat'), route.get(prefix + 'Lng')],
        style: this.getLineStyle(itinerary, route),
        title: route.get(prefix + 'Address')
      };
    }
  });

  var calcBoundsFromItineraries = function calcBoundsFromItineraries(itineraries) {
    return _ember['default'].A(itineraries.reduce(function (bounds, iti) {
      return bounds.concat(iti.get('routes').reduce(function (bnds, route) {
        return bnds.concat([[route.get('fromLat'), route.get('fromLng')].map(parseFloat), [route.get('toLat'), route.get('toLng')].map(parseFloat)]);
      }, []));
    }, []));
  };
});