define('a2b/components/create-new-route-carousel-form/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    children: _ember['default'].A([]),
    complete: false,
    countriesList: _ember['default'].computed('countries', function () {
      return this.get('countries').mapBy('text').join(', ');
    }),
    didInsertElement: function didInsertElement() {
      this.$('.carousel').carousel({
        interval: false,
        wrap: false,
        keyboard: false
      });
      this.rewindToCurrentStep();
    },
    onChangeStep: _ember['default'].observer('currentStep', function () {
      // If we go back to the first step, reset the form
      if (this.get('currentStep') === 1) {
        this.set('complete', false);
      }
      this.rewindToCurrentStep();
    }),
    rewindToCurrentStep: function rewindToCurrentStep() {
      this.$('.carousel').carousel(this.get('currentStep') - 1);
    },

    actions: {
      submit: function submit() {
        var _this = this;

        // Pass the callbacks to submit - they will be called when
        // request is finished
        this.get('submit')(function () {
          _this.send('next');
          _this.set('complete', true);
        }, function (error) {
          console.log(error);
          _this.send('next');
          _this.set('complete', true);
        });
      },
      next: function next() {
        this.incrementProperty('currentStep');
      },
      back: function back() {
        this.decrementProperty('currentStep');
      }
    }
  });
});