define('a2b/serializers/itinerary', ['exports', 'npm:ramda', 'ember-data'], function (exports, _npmRamda, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass,
      // Sort routes inside itinerary in proper order
      _npmRamda['default'].merge(payload, {
        data: sortRouteInItineraries(payload.data)
      }), id, requestType);
    }
  });

  var itineraryRoutesLens = _npmRamda['default'].lensPath(['relationships', 'routes', 'data']);

  var sortRouteInItineraries = function sortRouteInItineraries(data) {
    return Array.isArray(data) ? data.map(function (itinerary) {
      return _npmRamda['default'].over(itineraryRoutesLens, putRoutesInOrder(getRouteOrder(itinerary)), itinerary);
    }) : data;
  };

  var getRouteOrder = function getRouteOrder(itinerary) {
    return itinerary.id.split('-');
  };

  var putRoutesInOrder = _npmRamda['default'].curry(function (order, items) {
    return order.map(function (routeId) {
      return items.find(function (route) {
        return route.id === routeId;
      });
    });
  });
});