define("a2b/components/gmaps-wrapper/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "a2b/components/gmaps-wrapper/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "g-maps", [], ["name", "map", "lat", ["subexpr", "@mut", [["get", "center.0", ["loc", [null, [3, 4], [3, 14]]], 0, 0, 0, 0]], [], [], 0, 0], "lng", ["subexpr", "@mut", [["get", "center.1", ["loc", [null, [4, 4], [4, 14]]], 0, 0, 0, 0]], [], [], 0, 0], "zoom", ["subexpr", "@mut", [["get", "zoom", ["loc", [null, [5, 5], [5, 9]]], 0, 0, 0, 0]], [], [], 0, 0], "click", "mapClicked", "markers", ["subexpr", "@mut", [["get", "gMapsMarkers", ["loc", [null, [7, 8], [7, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "polylines", ["subexpr", "@mut", [["get", "gMapsLines", ["loc", [null, [8, 10], [8, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "loaded", "mapLoaded"], ["loc", [null, [1, 0], [9, 20]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});