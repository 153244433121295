define('a2b/components/search-route-form/component', ['exports', 'ember', 'npm:ramda'], function (exports, _ember, _npmRamda) {
  exports['default'] = _ember['default'].Component.extend({
    fromError: null,
    toError: null,
    classNames: ['form-wrapper'],
    actions: {
      search: function search() {
        var params = {
          fromLocation: this.get('from'),
          toLocation: this.get('to')
        };
        paramsAreValid(params) ? this.get('submitSearch')(params) : this.showErrors(composeErrors(params));
      }
    },
    showErrors: function showErrors(errors) {
      var _this = this;

      errors.map(function (err) {
        return _this.set(err.field + 'Error', err.error === 'empty' ? emptyMessage : sameLocationMessage);
      });
    }
  });

  var paramsAreValid = function paramsAreValid(params) {
    return composeErrors(params).length === 0;
  };

  var composeErrors = function composeErrors(params) {
    return _npmRamda['default'].reject(_npmRamda['default'].isNil, [!params.fromLocation || !params.fromLocation.get('id') || !params.fromLocation.get('name') ? { field: 'from', error: 'empty' } : null, !params.toLocation || !params.toLocation.get('id') || !params.toLocation.get('name') ? { field: 'to', error: 'empty' } : null, params.fromLocation && params.toLocation && params.fromLocation.get('id') === params.toLocation.get('id') ? { field: 'to', error: 'same-location' } : null]);
  };

  var emptyMessage = 'Search a location and select one of the values from the suggestions';
  var sameLocationMessage = 'Please choose a different location from the origin';
});