define('a2b/helpers/duration-string', ['exports', 'ember', 'a2b/helpers/pluralize'], function (exports, _ember, _a2bHelpersPluralize) {
  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var pluralize = _a2bHelpersPluralize['default'].compute;

  exports['default'] = _ember['default'].Helper.helper(function (_ref) {
    var _ref2 = _toArray(_ref);

    var duration = _ref2[0];

    var params = _ref2.slice(1);

    var mins = duration % 1 * 60;
    var hours = Math.floor(duration);
    return hours === 0 ? mins + ' minutes' : mins === 0 ? hours + ' ' + pluralize([hours, 'hour']) : hours + 'h ' + mins + 'm';
  });
});