define('a2b/authenticators/oauth2-credentials', ['exports', 'ember', 'ember-simple-auth/authenticators/oauth2-password-grant', 'a2b/config/environment'], function (exports, _ember, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _a2bConfigEnvironment) {
  var RSVP = _ember['default'].RSVP;
  var makeArray = _ember['default'].makeArray;
  var isEmpty = _ember['default'].isEmpty;
  var run = _ember['default'].run;
  var assign = _ember['default'].assign;

  var serverTokenEndpoint = _a2bConfigEnvironment['default'].a2b.apiEndPoint + '/session/create';

  exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
    authenticate: function authenticate(code) {
      var _this = this;

      var scope = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];
      var headers = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      return new RSVP.Promise(function (resolve, reject) {
        return _this.makeRequest(serverTokenEndpoint, makeRequestData(code, scope), headers).then(function (response) {
          return run(function () {
            return !isEmpty(response['access_token']) ? resolve(_this._prepareResponse(response)) : reject('access_token is missing in server response');
          });
        }, function (xhr) {
          return run(null, reject, _this.get('rejectWithXhr') ? xhr : xhr.responseJSON || xhr.responseText);
        });
      });
    },
    _prepareResponse: function _prepareResponse(response) {
      var expiresAt = this._absolutizeExpirationTime(response['expires_in']);
      this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);
      return !isEmpty(expiresAt) ? assign(response, { 'expires_at': expiresAt }) : response;
    }
  });

  var makeRequestData = function makeRequestData(code, scope) {
    return {
      'grant_type': 'authorization_code',
      code: code,
      scope: makeArray(scope).join(' ')
    };
  };
});