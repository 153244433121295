define('a2b/controllers/routes/new', ['exports', 'ember', 'a2b/models/route'], function (exports, _ember, _a2bModelsRoute) {
  exports['default'] = _ember['default'].Controller.extend({
    errorMessage: null,
    showSpinner: false,
    actions: {
      createRoute: function createRoute(route, resolve, reject) {
        var _this = this;

        this.set('showSpinner', true);
        this.prepareRoute(route).save().then(function () {
          _this.set('showSpinner', false);
          resolve();
        })['catch'](function (error) {
          _this.set('showSpinner', false);
          console.log('error:', error);
          console.log('error object:', serializeRoute(route));
          _this.set('errorMessage', error.message + ':\n ' + serializeRoute(route));
          reject();
        });
      },
      resetModel: function resetModel() {
        this.set('model', this.get('store').createRecord('route'));
      }
    },
    prepareRoute: function prepareRoute(route) {
      _a2bModelsRoute['default'].eachAttribute(function (name) {
        return _ember['default'].isEmpty(route.get(name)) ? route.set(name, "") : null;
      });
      return route;
    }
  });

  var serializeRoute = function serializeRoute(route) {
    return JSON.stringify(route.toJSON({ includeId: true }));
  };
});