define('a2b/models/itinerary', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    routes: _emberData['default'].hasMany('route'),
    transport: _ember['default'].computed('routes', function () {
      return getTransports(this.get('routes'));
    }),
    organizations: _ember['default'].computed('routes', function () {
      return getOrganizations(this.get('routes'));
    }),
    stops: _ember['default'].computed('routes', function () {
      return getStops(this.get('routes'));
    }),
    price: _emberData['default'].attr('number'),
    duration: _emberData['default'].attr('number')
  });

  var getTransports = function getTransports(routes) {
    return routes.mapBy('transportType').uniq().join(' + ');
  };

  var getOrganizations = function getOrganizations(routes) {
    return routes.mapBy('organization').uniq().join(' + ');
  };

  var getStops = function getStops(routes) {
    return routes.get('length') - 1;
  };
});