define('a2b/routes/itineraries', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),

    // Change header background color on this route to black
    activate: function activate() {
      this._super(arguments);
      _ember['default'].$('body').addClass('no-picture');
    },
    deactivate: function deactivate() {
      this._super(arguments);
      _ember['default'].$('body').removeClass('no-picture');
    },

    queryParams: {
      fromId: { refreshModel: true },
      toId: { refreshModel: true }
    },
    model: function model(params) {
      return params.fromId && params.toId ? _rsvp['default'].hash({
        itineraries: this.get('store').query('itinerary', {
          filter: {
            from: params.fromId,
            to: params.toId
          }
        }),
        from: this.get('store').findRecord('location', params.fromId),
        to: this.get('store').findRecord('location', params.toId)
      }) : _rsvp['default'].hash({
        itineraries: _rsvp['default'].resolve(_ember['default'].A([])),
        from: _rsvp['default'].resolve(_ember['default'].Object.create({ id: null, name: null })),
        to: _rsvp['default'].resolve(_ember['default'].Object.create({ id: null, name: null }))
      });
    },
    afterModel: function afterModel() {
      this.incrementProperty('session.searchNumber');
      this.controllerFor('index').set('showSpinner', false);
    }
  });
});