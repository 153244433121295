define("a2b/components/create-new-route-choose-location-address/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "a2b/components/create-new-route-choose-location-address/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "for", "address");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("small");
        dom.setAttribute(el3, "id", "addressHelp");
        dom.setAttribute(el3, "class", "form-text text-muted");
        var el4 = dom.createTextNode("\n      or enter it in the field below\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-text");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "#");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["content", "addressLabel", ["loc", [null, [2, 23], [2, 39]]], 0, 0, 0, 0], ["inline", "place-autocomplete-field", [], ["value", ["subexpr", "@mut", [["get", "addressText", ["loc", [null, [9, 8], [9, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "handlerController", ["subexpr", "@mut", [["get", "this", ["loc", [null, [10, 20], [10, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "inputClass", "form-control", "placeChangedCallback", "addressChanged", "types", "address", "restrictions", ["subexpr", "@mut", [["get", "countryRestriction", ["loc", [null, [14, 15], [14, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "23 Sukhumvit Road, Bangkok, Thailand", "aria-describedby", "addressHelp"], ["loc", [null, [8, 2], [17, 4]]], 0, 0], ["element", "action", ["toggleAddress"], ["on", "click"], ["loc", [null, [19, 16], [19, 53]]], 0, 0], ["content", "subAddressLabel", ["loc", [null, [20, 6], [20, 25]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});