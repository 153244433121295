define("a2b/components/create-new-route-details-transport/component", ["exports", "ember"], function (exports, _ember) {

  var transportTypes = ["Bus", "Ferry", "Train"];

  exports["default"] = _ember["default"].Component.extend({
    transportType: null,
    transportTypeFieldValues: _ember["default"].A(transportTypes),

    transportTypeEmpty: _ember["default"].computed('transportType', 'showOtherTransportField', function () {
      return !this.get('transportType') && !this.get('showOtherTransportField');
    }),
    showOtherTransportField: _ember["default"].computed('transportType', {
      get: function get() {
        return !!this.get('transportType') && transportTypes.indexOf(this.get('transportType')) === -1;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    actions: {
      transportTypeChanged: function transportTypeChanged(value) {
        value === 'Other' ? (this.set('transportType', ""), this.set('showOtherTransportField', true)) : this.set('transportType', value);
      }
    }
  });
});