define('a2b/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'a2b/config/environment'], function (exports, _emberData, _emberSimpleAuthMixinsDataAdapterMixin, _a2bConfigEnvironment) {
  exports['default'] = _emberData['default'].JSONAPIAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    authorizer: 'authorizer:oauth2',
    host: _a2bConfigEnvironment['default'].a2b.apiEndPoint,
    handleResponse: function handleResponse(status, headers, payload, req) {
      return payload.errors ? new _emberData['default'].AdapterError(payload.errors, getErrorMsg(req)) : this._super.apply(this, arguments);
    }
  });

  var getErrorMsg = function getErrorMsg(req) {
    return 'Server error occured while processing: \'' + req.method + ' ' + req.url + '\'';
  };
});