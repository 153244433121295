define('a2b/mixins/g-map-geocodes', ['exports', 'ember', 'npm:ramda'], function (exports, _ember, _npmRamda) {
  exports['default'] = _ember['default'].Mixin.create({
    gMap: _ember['default'].inject.service(),

    getAddressByPoint: function getAddressByPoint(point) {
      return this.get('gMap').geocode({
        lat: point[0],
        lng: point[1],
        language: 'en'
      }).then(function (geocodes) {
        return getAddressFromGeocodes(geocodes, point);
      }, function (err) {
        return console.error(err);
      });
    },

    getCountryByName: function getCountryByName(name) {
      return this.get('gMap').geocode({
        address: name
      }).then(function (geocodes) {
        return geocodes[0];
      }, function (err) {
        return console.error(err);
      });
    }
  });

  var getAddressFromGeocodes = function getAddressFromGeocodes(geocodes, point) {
    return _npmRamda['default'].merge(getGeocodeItem(geocodes, 'route') || getGeocodeItem(geocodes, 'street_address'),
    // Replace the coordinates by the ones user clicked on
    {
      geometry: {
        location: {
          lat: function lat() {
            return point[0];
          },
          lng: function lng() {
            return point[1];
          }
        }
      }
    });
  };

  var getGeocodeItem = function getGeocodeItem(geocodes, itemName) {
    return geocodes.find(function (item) {
      return item.types.includes(itemName);
    });
  };
});