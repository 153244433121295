define('a2b/components/create-new-route-details/component', ['exports', 'ember', 'a2b/models/route'], function (exports, _ember, _a2bModelsRoute) {
  exports['default'] = _ember['default'].Component.extend({
    newRoute: null,
    init: function init() {
      this._super.apply(this, arguments);
      // Generate a computed property which watches all parameters
      // of the model changes
      _ember['default'].defineProperty(this, 'showSubmitButton', _ember['default'].computed('newRoute.{' + getAttributeNames(_a2bModelsRoute['default']).mapBy('name').join(',') + '}', function () {
        return isRouteComplete(this.get('newRoute'));
      }));
    },
    actions: {
      submit: function submit() {
        this.get('submit')();
      },
      back: function back() {
        this.get('back')();
      }
    }
  });

  var getAttributeNames = function getAttributeNames(model) {
    var attrs = [];
    _ember['default'].get(model, 'attributes').forEach(function (name) {
      return attrs.push(name);
    });
    return attrs;
  };

  var isRouteComplete = function isRouteComplete(route) {
    return route.get('transportType') && route.get('organization') && route.get('duration') && route.get('price') && route.get('description') && isRouteLocationsSet(route);
  };

  var isRouteLocationsSet = function isRouteLocationsSet(route) {
    return isAddressAdded(route, 'from') && isAddressAdded(route, 'to');
  };

  var isAddressAdded = function isAddressAdded(route, prefix) {
    return route.get(prefix + 'Lat') && route.get(prefix + 'Lat') || route.get(prefix + 'Comment');
  };
});