define('a2b/router', ['exports', 'ember', 'a2b/config/environment', 'a2b/mixins/google-pageview', 'a2b/mixins/yandex-metrica'], function (exports, _ember, _a2bConfigEnvironment, _a2bMixinsGooglePageview, _a2bMixinsYandexMetrica) {

  var Router = _ember['default'].Router.extend(_a2bMixinsGooglePageview['default'], _a2bMixinsYandexMetrica['default'], {
    location: _a2bConfigEnvironment['default'].locationType,
    rootURL: _a2bConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('itineraries');

    this.route('routes', function () {
      this.route('new');
    });
    this.route('about');
  });

  exports['default'] = Router;
});