define("a2b/components/create-new-route/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "a2b/components/create-new-route/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "add-route-mobile");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "alert alert-warning");
          var el3 = dom.createTextNode("\n      Sorry, adding routes is not optimised for smartphones yet. Please try from a larger screen.\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "a2b/components/create-new-route/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "left-side");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "right-side");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "create-new-route-carousel-form", [], ["newRoute", ["subexpr", "@mut", [["get", "newRoute", ["loc", [null, [10, 13], [10, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "locations", ["subexpr", "@mut", [["get", "locations", ["loc", [null, [11, 14], [11, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "currentStep", ["subexpr", "@mut", [["get", "currentStep", ["loc", [null, [12, 16], [12, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "submit", ["subexpr", "action", ["submit"], [], ["loc", [null, [13, 11], [13, 28]]], 0, 0], "resetForm", ["subexpr", "action", ["resetForm"], [], ["loc", [null, [14, 14], [14, 34]]], 0, 0], "onCountryChanged", ["subexpr", "action", ["onCountryChanged"], [], ["loc", [null, [15, 21], [15, 48]]], 0, 0], "errorMessage", ["subexpr", "@mut", [["get", "errorMessage", ["loc", [null, [16, 17], [16, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "showSpinner", ["subexpr", "@mut", [["get", "showSpinner", ["loc", [null, [17, 16], [17, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "countries", ["subexpr", "@mut", [["get", "countries", ["loc", [null, [18, 14], [18, 23]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 4], [19, 6]]], 0, 0], ["inline", "create-new-route-map", [], ["locations", ["subexpr", "@mut", [["get", "locations", ["loc", [null, [23, 14], [23, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "currentStep", ["subexpr", "@mut", [["get", "currentStep", ["loc", [null, [24, 16], [24, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "mapClicked", ["subexpr", "action", ["mapClicked"], [], ["loc", [null, [25, 15], [25, 36]]], 0, 0]], ["loc", [null, [22, 4], [26, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "a2b/components/create-new-route/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "media.isMobile", ["loc", [null, [1, 6], [1, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [29, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});