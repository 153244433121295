define('a2b/components/create-new-route/component', ['exports', 'npm:ramda', 'ember', 'rsvp', 'a2b/mixins/g-map-geocodes'], function (exports, _npmRamda, _ember, _rsvp, _a2bMixinsGMapGeocodes) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var locationsNumber = 2;
  var countries = [{ text: "Vietnam", value: "vn" }, { text: "Cambodia", value: "kh" }, { text: "Laos", value: "la" }, { text: "Myanmar", value: "mm" }, { text: "Thailand", value: "th" }, { text: "Malaysia", value: "my" }, { text: "Brunei", value: "bn" }, { text: "East Timor", value: "tl" }, { text: "Indonesia", value: "id" }, { text: "Singapore", value: "sg" }, { text: "Philippines", value: "ph" }];

  exports['default'] = _ember['default'].Component.extend(_a2bMixinsGMapGeocodes['default'], {
    classNames: ['row'],
    classNameBindings: ['media.isMobile::bottom-split', 'media.isMobile::add-route-form'],

    session: _ember['default'].inject.service(),

    errorMessage: null,
    currentStep: 1,
    showLoginModal: false,
    locations: getBlankLocations(locationsNumber),
    countries: _ember['default'].A(countries),
    onLoginSuccess: function onLoginSuccess() {},
    onLocationChanged: _ember['default'].observer('locations.@each.{country,city,address,comment}', function () {
      this.get('newRoute').setProperties(locationToModelProps(this.get('locations')[0], 'from'));
      this.get('newRoute').setProperties(locationToModelProps(this.get('locations')[1], 'to'));
    }),
    init: function init() {
      this._super.apply(this, arguments);
      // Reset the values to  reset the form
      this.resetLocations();
      this.set('currentStep', 1);
      this.set('errorMessage', null);
    },

    actions: {
      submit: function submit(success, failure) {
        this.get('createRoute')(this.get('newRoute'), success, failure);
      },
      mapClicked: function mapClicked(point) {
        var _this = this;

        if (this.getCurrentLocation().country && this.getCurrentLocation().city) {
          this.getAddressByPoint(point).then(function (address) {
            return _this.getCurrentLocation().setProperties({ address: address });
          });
        }
      },
      onCountryChanged: function onCountryChanged(code) {
        var _this2 = this;

        this.getCountryByName(this.getCountryByCode(code)).then(function (country) {
          return {
            country: country,
            city: null
          };
        }).then(function (props) {
          return _this2.getCurrentLocation().setProperties(props);
        });
      },
      onLoginSuccess: function onLoginSuccess() {
        this.get('onLoginSuccess')();
      },
      resetForm: function resetForm() {
        this.resetLocations();
        this.get('resetModel')();
        this.set('currentStep', 1);
        this.set('errorMessage', null);
      }
    },
    getCountryByCode: function getCountryByCode(code) {
      return this.get('countries').find(function (item) {
        return item.value === code;
      }).text;
    },
    getCurrentLocation: function getCurrentLocation() {
      return this.get('locations.' + (this.get('currentStep') - 1));
    },
    resetLocations: function resetLocations() {
      this.set('locations', getBlankLocations(locationsNumber));
    }
  });

  // This is called at component creation, so need to be defined
  // at the top of the file
  function getBlankLocations(number) {
    return _ember['default'].A(_npmRamda['default'].times(function (i) {
      return _ember['default'].Object.create({
        country: null,
        city: null,
        address: null,
        geocodes: _ember['default'].A([]),
        comment: null
      });
    }, number));
  }

  var getFormattedAddr = function getFormattedAddr(addr, prop) {
    return addr && addr[prop] && addr[prop].formatted_address ? addr[prop].formatted_address : null;
  };

  var locationToModelProps = function locationToModelProps(loc, prefix) {
    var _R$merge, _ref;

    return _npmRamda['default'].merge((_R$merge = {}, _defineProperty(_R$merge, prefix + 'City', getFormattedAddr(loc, "city")), _defineProperty(_R$merge, prefix + 'Address', getFormattedAddr(loc, "address")), _defineProperty(_R$merge, prefix + 'Comment', loc.comment), _R$merge), loc.address && loc.address.geometry.location ? (_ref = {}, _defineProperty(_ref, prefix + 'Lat', loc.address.geometry.location.lat()), _defineProperty(_ref, prefix + 'Lng', loc.address.geometry.location.lng()), _ref) : {});
  };
});