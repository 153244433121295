define('a2b/components/create-new-route-choose-location-address/component', ['exports', 'ember', 'npm:ramda'], function (exports, _ember, _npmRamda) {
  exports['default'] = _ember['default'].Component.extend({
    address: null,
    addressText: _ember['default'].computed('address', {
      get: function get(key) {
        return _npmRamda['default'].propOr('', 'formatted_address', this.get('address'));
      },
      set: function set(key, value) {
        return value;
      }
    }),
    actions: {
      toggleAddress: function toggleAddress() {
        this.get('toggleAddress')();
      },
      addressChanged: function addressChanged(obj) {
        this.set('address', obj);
      }
    }
  });
});