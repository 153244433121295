define("a2b/components/nav-bar/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    session: _ember["default"].inject.service(),
    tagName: "nav",
    classNames: ["navbar", "navbar-full"],
    actions: {
      openOverlay: function openOverlay() {
        this.set('menuOpen', true);
      },
      closeOverlay: function closeOverlay() {
        this.set('menuOpen', false);
      }
    }
  });
});