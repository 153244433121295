define('a2b/components/create-new-route-map/component', ['exports', 'npm:ramda', 'ember'], function (exports, _npmRamda, _ember) {

  var asia = {
    lat: 15,
    lng: 100,
    zoom: 4
  };
  var dragCursor = 'hand';
  var pointCursor = 'cell';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['create-new-map'],
    markers: _ember['default'].A([]),
    lines: _ember['default'].A([]),
    bounds: _ember['default'].A([]),
    center: [asia.lat, asia.lng],
    zoom: asia.zoom,
    cursor: dragCursor,
    init: function init() {
      this._super.apply(this, arguments);
      this.focusOnLocations = _npmRamda['default'].compose(this.focusOnBounds.bind(this), locationsToBounds);
    },

    onLocationChange: _ember['default'].observer('locations.@each.{country,city,address,comment}', function () {
      this.set('lines', createLines(this.getAddresses()));
      this.set('markers', createMarkers(this.getAddresses()));
      this.updateMap();
    }),
    onCurrentStepChange: _ember['default'].observer('currentStep', function () {
      this.updateMap();
    }),

    updateMap: function updateMap() {
      // Move the center to a point or bounds
      this.focusOnLocations(this.getVisibleLocations());

      // Change zoom level
      this.set('zoom', calculateZoom(this.getCurrentLocation(), this.get('zoom')));

      // Change cursor shape
      this.set('cursor', this.isAtFinalStep() || !this.getCurrentLocation().city ? dragCursor : pointCursor);
    },
    focusOnBounds: function focusOnBounds(bounds) {
      return bounds.length > 1 ? this.set('bounds', bounds) : this.set('center', bounds[0]);
    },
    isAtFinalStep: function isAtFinalStep() {
      return this.get('currentStep') > this.get('locations').length;
    },
    getVisibleLocations: function getVisibleLocations() {
      // If this is the last step, return all the locations,
      // otherwise return the current one
      return this.isAtFinalStep() ? this.get('locations') : [this.getCurrentLocation()];
    },
    getCurrentLocation: function getCurrentLocation() {
      return this.get('locations')[this.get('currentStep') - 1];
    },
    getAddresses: function getAddresses() {
      return this.get('locations').mapBy('address').compact();
    },
    actions: {
      mapClicked: function mapClicked(point) {
        this.get('mapClicked')(point);
      }
    }
  });

  var getViewportBounds = function getViewportBounds(viewport) {
    return [[viewport.getNorthEast().lat(), viewport.getNorthEast().lng()], [viewport.getSouthWest().lat(), viewport.getSouthWest().lng()]];
  };

  var getAddressBounds = function getAddressBounds(address) {
    return [[address.geometry.location.lat(), address.geometry.location.lng()]];
  };

  var locationsToBounds = _npmRamda['default'].reduce(function (bounds, loc) {
    return loc.address ? bounds.concat(getAddressBounds(loc.address)) : loc.city || loc.country ? bounds.concat(getViewportBounds((loc.city || loc.country).geometry.viewport)) : _npmRamda['default'].append([asia.lat, asia.lng], bounds);
  }, []);

  var calculateZoom = function calculateZoom(location, currentZoom) {
    return !location ? currentZoom : location.address && currentZoom < 12 ? 12 : !location.address && !location.city && !location.country ? asia.zoom : currentZoom;
  };

  var createSelectedLine = function createSelectedLine(prevAddress, currentAddress) {
    return {
      id: prevAddress.formatted_address + '-' + currentAddress.formatted_address,
      from: [prevAddress.geometry.location.lat(), prevAddress.geometry.location.lng()],
      to: [currentAddress.geometry.location.lat(), currentAddress.geometry.location.lng()],
      style: 'selected'
    };
  };

  var createLines = function createLines(addresses) {
    return _ember['default'].A(addresses.reduce(function (lines, addr, index, array) {
      return(
        // If this is at least second address in the array,
        // add a line between it and previous one,
        // otherwise do thing
        array[index - 1] ? _npmRamda['default'].append(createSelectedLine(array[index - 1], addr), lines) : lines
      );
    }, []));
  };

  var createMarkers = function createMarkers(addresses) {
    return _ember['default'].A(addresses.map(function (addr) {
      return {
        id: '' + addr.formatted_address,
        coords: [addr.geometry.location.lat(), addr.geometry.location.lng()],
        style: 'selected',
        title: addr.formatted_address
      };
    }));
  };
});