define('a2b/components/itinerary-details/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    itinerary: null,
    routeHovered: null,
    routeOpened: null,

    actions: {
      routeHovered: function routeHovered(route) {
        this.set('routeHovered', route);
      },
      routeClicked: function routeClicked(route) {
        this.set('routeHovered', route);
        this.set('routeOpened', route);
      }
    }
  });
});