define("a2b/components/location-autocomplete/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "a2b/components/location-autocomplete/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "input-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-button");
        var el3 = dom.createTextNode("×");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["inline", "input", [], ["classNames", "form-control", "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [4, 8], [4, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "autocomplete", "off", "spellcheck", false, "input", ["subexpr", "action", ["handleInput"], [], ["loc", [null, [7, 8], [7, 30]]], 0, 0], "focusIn", ["subexpr", "action", ["focusInput"], [], ["loc", [null, [8, 10], [8, 31]]], 0, 0], "focusOut", ["subexpr", "action", ["blurInput"], [], ["loc", [null, [9, 11], [9, 31]]], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [10, 14], [10, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [11, 4]]], 0, 0], ["element", "action", ["clear"], ["on", "click"], ["loc", [null, [12, 28], [12, 57]]], 0, 0], ["inline", "location-autocomplete-suggestions", [], ["suggestions", ["subexpr", "@mut", [["get", "suggestions", ["loc", [null, [16, 12], [16, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "isLoading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [17, 10], [17, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "show", ["subexpr", "@mut", [["get", "showSuggestions", ["loc", [null, [18, 5], [18, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "selectSuggestion", ["subexpr", "action", ["selectSuggestion"], [], ["loc", [null, [19, 17], [19, 44]]], 0, 0], "noMatchesSuggestion", ["subexpr", "@mut", [["get", "noMatchesSuggestion", ["loc", [null, [20, 20], [20, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 0], [21, 2]]], 0, 0], ["inline", "location-autocomplete-error", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [24, 6], [24, 11]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [25, 6], [25, 11]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [23, 0], [26, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});