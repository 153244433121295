define('a2b/components/gmaps-wrapper/component', ['exports', 'ember', 'npm:ramda'], function (exports, _ember, _npmRamda) {
  exports['default'] = _ember['default'].Component.extend({
    /*
     * Array of lines
     * [{
     *   id: 'for-reference',
     *   from: [123, 1231],
     *   to: [213, 123],
     *   style: 'dimmed|normal|selected', // dimmed is default
     * }]
     *
     */
    lines: _ember['default'].A([]),

    /* Array of markers
     * [{
     *   id: 'for-reference',
     *   coords: [123, 3123],
     *   style: 'dimmed|normal|selected', // dimmed is default
     *   title: "this is a title"
     * }]
     */
    markers: _ember['default'].A([]),

    /*
     * Array of coordinates:
     * Ember.A([
     *   [123, 123],
     *   [123, 123],
     *   [123, 123]
     * ])
     */
    bounds: null,
    center: [15, 100],
    zoom: 4,
    mapClicked: function mapClicked() {},
    cursorShape: 'hand',

    /*
     * Converts lines array into line objects google map can understand
     */
    gMapsLines: _ember['default'].computed('lines.@each.{from,to,style}', function () {
      return calcGMapsLines(this.get('lines'));
    }),

    /*
     * Converts markers array into marker objects google map can understand
     */
    gMapsMarkers: _ember['default'].computed('markers.[]', function () {
      return calcGMapsMarkers(this.get('markers'));
    }),

    onBoundsChange: _ember['default'].observer('bounds', function () {
      this.zoomToBounds();
    }),
    onCursorChange: _ember['default'].observer('cursorShape', function () {
      this.setCursor();
    }),

    classNames: ['gmaps-wrapper'],
    actions: {
      mapLoaded: function mapLoaded() {
        this.setCursor();
        this.zoomToBounds();
      },
      mapClicked: function mapClicked(e) {
        this.get('mapClicked')([e.latLng.lat(), e.latLng.lng()]);
      }
    },
    gMap: _ember['default'].inject.service('gMap'),
    setCursor: function setCursor() {
      this.get('gMap').maps.select('map').map.setOptions({ draggableCursor: this.get('cursorShape') });
    },
    zoomToBounds: function zoomToBounds() {
      var gBounds = converToGoogleBounds(this.get('bounds'));
      if (!gBounds.isEmpty()) {
        this.get('gMap').maps.select('map').map.fitBounds(gBounds);
      }
    }
  });

  var converToGoogleBounds = function converToGoogleBounds(bounds) {
    return bounds.reduce(function (b, point) {
      return b.extend({ lat: point[0], lng: point[1] });
    }, new google.maps.LatLngBounds());
  };

  var calcGMapsLines = function calcGMapsLines(lines) {
    return _ember['default'].A(lines.map(function (line) {
      return _npmRamda['default'].merge({
        id: line.id,
        path: [line.from, line.to],
        clickable: true,
        editable: false,
        geodesic: true,
        visible: true,
        strokeColor: mapColors[line.style],
        zIndex: 1
      }, lineStyles[line.style]);
    }));
  };

  var calcGMapsMarkers = function calcGMapsMarkers(markers) {
    return _ember['default'].A(markers.map(function (marker) {
      return {
        id: marker.id,
        lat: marker.coords[0],
        lng: marker.coords[1],
        infoWindow: { content: marker.title },
        zIndex: lineStyles[marker.style].zIndex + 1,
        icon: _npmRamda['default'].merge({
          path: google.maps.SymbolPath.CIRCLE,
          strokeColor: mapColors[marker.style],
          strokeWeight: 2,
          fillColor: "white",
          fillOpacity: 1
        }, markerStyles[marker.style])
      };
    }));
  };

  var mapColors = {
    dimmed: 'grey',
    normal: '#2c9bba',
    selected: '#f94572'
  };

  var lineStyles = {
    dimmed: {
      strokeOpacity: 0.7,
      strokeWeight: 1,
      zIndex: 1
    },
    normal: {
      strokeOpacity: 1,
      strokeWeight: 3,
      zIndex: 3
    },
    selected: {
      strokeOpacity: 1,
      strokeWeight: 3,
      zIndex: 5
    }
  };

  var markerStyles = {
    dimmed: {
      strokeOpacity: 0.7,
      scale: 5
    },
    normal: {
      strokeOpacity: 1,
      scale: 7
    },
    selected: {
      strokeOpacity: 1,
      scale: 7
    }
  };
});