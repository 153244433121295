define('a2b/mirage/config', ['exports', 'ember-cli-mirage', 'a2b/config/environment', 'a2b/mirage/sessionResponse', 'a2b/mirage/apiData'], function (exports, _emberCliMirage, _a2bConfigEnvironment, _a2bMirageSessionResponse, _a2bMirageApiData) {
  exports['default'] = function () {
    this.namespace = _a2bConfigEnvironment['default'].a2b.apiEndPoint;

    // Searching itineraries
    this.get('/itineraries', function (_ref, _ref2) {
      var locations = _ref.locations;
      var queryParams = _ref2.queryParams;

      var from = locations.find(queryParams['filter[from]']);
      var to = locations.find(queryParams['filter[to]']);
      return from && to ? (0, _a2bMirageApiData['default'])(from.name, to.name) : makeError(200);
    });

    // Add route
    this.post('/routes', function (_ref3, _ref4) {
      var routes = _ref3.routes;
      var requestBody = _ref4.requestBody;
      return routes.create(JSON.parse(requestBody));
    });

    // Create a user session
    this.post('/session/create', _a2bMirageSessionResponse['default']);

    // Create a user record on the server
    this.post('/users', function (schema, request) {
      return {
        data: null
      };
    });

    // Search locations
    this.get('/locations', function (_ref5, _ref6) {
      var locations = _ref5.locations;
      var queryParams = _ref6.queryParams;
      return filterLocationsByName(queryParams['filter[name]'].toLowerCase(), locations);
    });

    // Get location by ID
    this.get('/locations/:id', function (_ref7, _ref8) {
      var locations = _ref7.locations;
      var params = _ref8.params;
      return locations.find(params.id) || makeError(400);
    });

    // Pass it to the actual API
    /*
    this.passthrough('/itineraries', ['get']);
    this.passthrough('/locations', ['get']);
    this.passthrough('/locations/:id', ['get']);
    this.passthrough('/routes', ['post']);
    this.passthrough('/users', ['post']);
    this.passthrough('/session/create', ['post']);
    */
    this.passthrough('https://mc.yandex.ru/**');
  };

  var makeError = function makeError(code) {
    return new _emberCliMirage['default'].Response(code, {}, {
      "errors": [{ "detail": "java.lang.IllegalStateException" }]
    });
  };

  var filterLocationsByName = function filterLocationsByName(name, locations) {
    return locations.all().filter(function (item) {
      return item.name.toLowerCase().indexOf(name) !== -1;
    });
  };
});