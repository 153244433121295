define('a2b/components/auth-form-short/component', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    errorMessage: "",
    email: "",
    showSpinner: false,
    actions: {
      submitForm: function submitForm() {
        this.set('showSpinner', true);
        this.register();
      }
    },
    register: function register() {
      var _this = this,
          _arguments = arguments;

      var user = this.get('store').createRecord('user');
      user.set('email', this.get('email'));
      user.set('password', '');
      if (!this.validateUser(user)) {
        this.set('showSpinner', false);
        return false;
      }

      user.save()['catch'](function (error) {
        return _this.set('errorMessage', error);
      }).then(function () {
        return _this.get('session').authenticate('authenticator:oauth2', user.get('email'), user.get('password'));
      }).then(function () {
        _this.set('showSpinner', false);
        return _rsvp['default'].resolve.apply(_rsvp['default'], _arguments);
      }).then(this.get('authComplete'));
    },
    validateUser: function validateUser(user) {
      this.set('errors', { email: false });
      this.set('errors.email', isEmailHasErrors(user.get('email')));
      return !this.get('errors').email;
    }
  });

  var isEmailHasErrors = function isEmailHasErrors(email) {
    return !email || !/.+@.+\..{2,}/.exec(email);
  };
});