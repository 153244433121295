define('a2b/components/create-new-route-choose-location/component', ['exports', 'ember', 'npm:ramda'], function (exports, _ember, _npmRamda) {
  exports['default'] = _ember['default'].Component.extend({
    location: null,
    showAddress: true,

    isCountrySet: _ember['default'].computed('location.country', function () {
      return !!_npmRamda['default'].prop('formatted_address', this.get('location.country'));
    }),
    isCitySet: _ember['default'].computed('location.city', function () {
      return !!_npmRamda['default'].prop('formatted_address', this.get('location.city'));
    }),
    isAddressSet: _ember['default'].computed('location.{address,comment}', function () {
      return this.get('location.comment') || !!_npmRamda['default'].prop('formatted_address', this.get('location.address'));
    }),

    countryRestriction: _ember['default'].computed('location.country', function () {
      return {
        country: this.get('location.country').address_components[0].short_name
      };
    }),

    onCityChanged: _ember['default'].observer('location.city', function () {
      this.set('location.address', null);
      this.set('location.comment', null);
      this.set('showAddress', true);
    }),

    actions: {
      toggleAddress: function toggleAddress() {
        this.toggleProperty('showAddress');
      },
      next: function next() {
        this.get('next')();
      },
      back: function back() {
        this.get('back')();
      }
    }
  });
});