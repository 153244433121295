define('a2b/components/location-autocomplete/component', ['exports', 'ember'], function (exports, _ember) {

  var maxSuggestions = 5;
  var minQueryLength = 1;

  exports['default'] = _ember['default'].Component.extend({
    locations: _ember['default'].inject.service(),
    classNames: ['autocomplete-field'],
    isFocused: false,
    location: null,
    suggestions: _ember['default'].A([]),
    valueSelected: false,
    value: _ember['default'].computed('location.name', {
      get: function get() {
        return this.get('location.name');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isLoading: _ember['default'].computed('suggestions.[]', {
      // When suggestions update, remove the loader spinner
      get: function get() {
        return false;
      },
      set: function set(k, v) {
        return v;
      }
    }),
    showSuggestions: _ember['default'].computed('suggestions.[]', 'isFocused', function () {
      return this.get('value.length') >= minQueryLength && this.get('isFocused') && !this.get('valueSelected') && !this.get('isLoading');
    }),

    actions: {
      handleInput: function handleInput() {
        if (this.get('value').length >= minQueryLength) {
          this.set('suggestions', _ember['default'].A([]));
          this.set('valueSelected', false);
          this.set('isLoading', true);
          _ember['default'].run.debounce(this, this.searchLocations, 300);
        }
      },
      selectSuggestion: function selectSuggestion(sugg) {
        this.set('location', sugg);
        this.set('valueSelected', true);
        this.set('suggestions', _ember['default'].A([]));
      },
      focusInput: function focusInput() {
        this.set('isFocused', true);
      },
      blurInput: function blurInput(e) {
        // Unfocus only if the new focus is another element
        if (this.$(e.relatedTarget).parents('div.suggestions').length === 0) {
          this.set('isFocused', false);
        }
      },
      clear: function clear() {
        this.set('value', '');
        this.set('location', null);
      }
    },
    searchLocations: function searchLocations() {
      var _this = this;

      this.get('locations').filter(this.get('value')).then(function (res) {
        return res.slice(0, maxSuggestions);
      }).then(function (suggestions) {
        return _this.set('suggestions', suggestions);
      });
    }
  });
});