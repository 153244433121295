define('a2b/mixins/controller-with-itinerary-search', ['exports', 'ember', 'npm:ramda'], function (exports, _ember, _npmRamda) {
  exports['default'] = _ember['default'].Mixin.create({
    showSpinner: false,
    actions: {
      search: function search(params) {
        var _this = this;

        this.set('showSpinner', true);
        this.transitionToRoute('itineraries', composeQueryParams(params)).then(function () {
          _this.set('showSpinner', false);
        });
      }
    }
  });

  var composeQueryParams = function composeQueryParams(params) {
    return {
      queryParams: {
        fromId: params.fromLocation.get('id'),
        fromCity: params.fromLocation.get('name'),
        toId: params.toLocation.get('id'),
        toCity: params.toLocation.get('name')
      }
    };
  };
});