define('a2b/components/create-new-route-details-duration/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    duration: null,
    durationHours: _ember['default'].computed('duration', {
      get: function get() {
        return getHours(this.get('duration'));
      },
      set: function set(key, value) {
        this.set('duration', addHours(this.get('duration'), value));
        return value;
      }
    }),
    durationMinutes: _ember['default'].computed('duration', {
      get: function get() {
        return getMinutes(this.get('duration'));
      },
      set: function set(key, value) {
        this.set('duration', addMinutes(this.get('duration'), value));
        return value;
      }
    })
  });

  var getHours = function getHours(time) {
    return time ? Math.floor(time) : null;
  };

  var getMinutes = function getMinutes(time) {
    return time ? Math.round(time % 1 * 60) : null;
  };

  var addHours = function addHours(time, hours) {
    return (time || 0) % 1 + parseInt(hours);
  };

  var addMinutes = function addMinutes(time, minutes) {
    return Math.floor(time || 0) + parseInt(minutes || 0) / 60;
  };
});