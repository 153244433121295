define('ember-place-autocomplete/components/place-autocomplete-field', ['exports', 'ember', 'ember-place-autocomplete/templates/components/place-autocomplete-field'], function (exports, _ember, _emberPlaceAutocompleteTemplatesComponentsPlaceAutocompleteField) {
  'use strict';

  var Component = _ember['default'].Component;
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  var typeOf = _ember['default'].typeOf;
  var isEqual = _ember['default'].isEqual;
  var run = _ember['default'].run;

  exports['default'] = Component.extend({
    layout: _emberPlaceAutocompleteTemplatesComponentsPlaceAutocompleteField['default'],
    disabled: false,
    inputClass: 'place-autocomplete--input',
    types: 'geocode',
    restrictions: {},
    tabindex: 0,
    withGeoLocate: false,

    // @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
    geolocate: function geolocate() {
      var _this = this;

      var navigator = this.get('navigator') || (window ? window.navigator : null);
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var google = _this.get('google') || window.google;
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          _this.get('autocomplete').setBounds(circle.getBounds());
        });
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this, 'setupComponent');
    },

    setupComponent: function setupComponent() {
      var _this2 = this;

      this.getAutocomplete();
      this.get('autocomplete').addListener('place_changed', function () {
        run(function () {
          _this2.placeChanged();
        });
      });
      if (this.get("withGeoLocate")) {
        this.geolocate();
      }
    },

    willDestroy: function willDestroy() {
      if (isPresent(this.get('autocomplete'))) {
        var google = this.get('google') || (window ? window.google : null);
        if (google) {
          google.maps.event.clearInstanceListeners(this.get('autocomplete'));
        }
      }
    },

    getAutocomplete: function getAutocomplete() {
      if (isEmpty(this.get('autocomplete'))) {
        if (document && window) {
          var inputElement = document.getElementById(this.elementId).getElementsByTagName('input')[0],
              google = this.get('google') || window.google,

          //TODO: check how to use the inyected google object
          autocomplete = new google.maps.places.Autocomplete(inputElement, { types: this._typesToArray(), componentRestrictions: this.get('restrictions') });
          this.set('autocomplete', autocomplete);
        }
      }
    },

    placeChanged: function placeChanged() {
      var place = this.get('autocomplete').getPlace();
      this._callCallback('placeChangedCallback', place);
      this.set('value', place.formatted_address);
    },

    _callCallback: function _callCallback(callback, place) {
      var callbackFn = this.attrs[callback];
      if (isEqual(typeOf(callbackFn), 'function')) {
        callbackFn(place);
      } else {
        var actionName = this.get(callback);
        if (isPresent(this.get('handlerController')) && isPresent(actionName)) {
          this.get('handlerController').send(actionName, place);
        }
      }
    },

    _typesToArray: function _typesToArray() {
      if (this.get('types') !== "") {
        return this.get('types').split(',');
      } else {
        return [];
      }
    },

    actions: {
      focusOut: function focusOut() {
        this._callCallback('focusOutCallback');
      }
    }
  });
});