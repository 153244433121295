define('a2b/components/location-autocomplete-error/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    onInputValueChange: _ember['default'].observer('value', function () {
      this.set('error', null);
    }),
    onErrorChange: _ember['default'].observer('error', function () {
      this.get('error') ? this.showErrorPopover(this.get('error')) : this.hideErrorPopover();
    }),
    willDestroyElement: function willDestroyElement() {
      this.set('error', null);
    },
    showErrorPopover: function showErrorPopover(text) {
      this.$().popover(constructPopover(text));
      this.$().popover('show');
    },
    hideErrorPopover: function hideErrorPopover() {
      this.$().popover('dispose');
    }
  });

  var constructPopover = function constructPopover(text) {
    return {
      content: text,
      trigger: 'focus,click',
      placement: 'bottom',
      template: '<div class="popover error-popover" role="tooltip">\n  <div class="popover-arrow"></div>\n  <h3 class="popover-title"></h3>\n  <div class="popover-content"></div>\n  </div>'
    };
  };
});