define('a2b/components/location-autocomplete-suggestions/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    suggestions: _ember['default'].A([]),
    isLoading: false,
    show: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().parent('.autocomplete-field').keyup(this.keyUpCallback.bind(this));
    },

    actions: {
      selectSuggestion: function selectSuggestion(sugg) {
        this.get('selectSuggestion')(sugg);
      }
    },
    keyUpCallback: function keyUpCallback(e) {
      if (this.get('show')) {
        // Up and down arrow keys
        switch (e.keyCode) {
          case 38:
            this.scrollSuggestions('up');
            break;
          case 40:
            this.scrollSuggestions('down');
            break;
        }
      }
    },
    scrollSuggestions: function scrollSuggestions(direction) {
      this.$(getNextSuggestionSelector(this.$('a:focus'), this.get('suggestions.length'), direction)).focus();
    }
  });

  var getNextSuggestionSelector = function getNextSuggestionSelector(currentFocusedElement, suggestionsNumber, direction) {
    return currentFocusedElement.length === 0 ? 'a:first' : 'a[data-index="' + getNextSuggestionIndex(suggestionsNumber, parseInt(currentFocusedElement.attr('data-index')), direction) + '"]';
  };

  var getNextSuggestionIndex = function getNextSuggestionIndex(suggestionsNumber, currentIndex, direction) {
    return cycleValue(0, suggestionsNumber - 1, direction === 'up' ? currentIndex - 1 : currentIndex + 1);
  };

  var cycleValue = function cycleValue(min, max, value) {
    return value > max ? min : value < min ? max : value;
  };
});