define('a2b/components/create-new-route-choose-location-city/component', ['exports', 'ember', 'npm:ramda'], function (exports, _ember, _npmRamda) {
  exports['default'] = _ember['default'].Component.extend({
    city: null,
    cityText: _ember['default'].computed('city', {
      get: function get(key) {
        return _npmRamda['default'].propOr('', 'formatted_address', this.get('city'));
      },
      set: function set(key, value) {
        return value;
      }
    }),
    actions: {
      cityChanged: function cityChanged(obj) {
        if (obj.address_components) {
          this.set('city', obj);
        }
      }
    }
  });
});