define('a2b/models/route', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    itinerary: _emberData['default'].hasMany('itinerary'),

    fromAddress: _emberData['default'].attr("string"),
    fromCity: _emberData['default'].attr("string"),
    fromComment: _emberData['default'].attr("string"),
    fromLat: _emberData['default'].attr("number"),
    fromLng: _emberData['default'].attr("number"),

    toAddress: _emberData['default'].attr("string"),
    toCity: _emberData['default'].attr("string"),
    toComment: _emberData['default'].attr("string"),
    toLat: _emberData['default'].attr("number"),
    toLng: _emberData['default'].attr("number"),

    organization: _emberData['default'].attr("string"),
    companyMail: _emberData['default'].attr("string"),
    companyPhone: _emberData['default'].attr("string"),
    companySite: _emberData['default'].attr("string"),
    companyDescription: _emberData['default'].attr("string"),

    transportType: _emberData['default'].attr("string"),
    duration: _emberData['default'].attr("string"),
    price: _emberData['default'].attr("string"),
    timetable: _emberData['default'].attr("string"),
    description: _emberData['default'].attr("string"),

    title: _ember['default'].computed('transportType', 'toCity', function () {
      return this.get('transportType') + ' to ' + this.get('toCity');
    })
  });
});