define('a2b/controllers/itineraries', ['exports', 'ember', 'npm:ramda', 'a2b/mixins/controller-with-itinerary-search'], function (exports, _ember, _npmRamda, _a2bMixinsControllerWithItinerarySearch) {
  exports['default'] = _ember['default'].Controller.extend(_a2bMixinsControllerWithItinerarySearch['default'], {
    session: _ember['default'].inject.service(),
    queryParams: ['fromId', 'toId', 'fromCity', 'toCity'],
    fromId: null,
    toId: null,
    fromCity: null,
    toCity: null,

    sortParams: ['stops', 'duration'],
    sortedItineraries: _ember['default'].computed.sort('model.itineraries', 'sortParams'),
    formFilled: _ember['default'].computed.and('fromId', 'toId')
  });
});