define("a2b/components/create-new-route-map/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "a2b/components/create-new-route-map/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "gmaps-wrapper", [], ["lines", ["subexpr", "@mut", [["get", "lines", ["loc", [null, [2, 6], [2, 11]]], 0, 0, 0, 0]], [], [], 0, 0], "markers", ["subexpr", "@mut", [["get", "markers", ["loc", [null, [3, 8], [3, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "bounds", ["subexpr", "@mut", [["get", "bounds", ["loc", [null, [4, 7], [4, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "center", ["subexpr", "@mut", [["get", "center", ["loc", [null, [5, 7], [5, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "zoom", ["subexpr", "@mut", [["get", "zoom", ["loc", [null, [6, 5], [6, 9]]], 0, 0, 0, 0]], [], [], 0, 0], "mapClicked", ["subexpr", "action", ["mapClicked"], [], ["loc", [null, [7, 11], [7, 32]]], 0, 0], "cursorShape", ["subexpr", "@mut", [["get", "cursor", ["loc", [null, [8, 12], [8, 18]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [9, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});